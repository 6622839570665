import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin(() => {
    const runTimeConfig = useRuntimeConfig();
    useGqlError((err) => {
      // Only log in dev or test environments
      if (runTimeConfig.public.environmentName !== 'production') {
        for (const gqlError of err.gqlErrors) {
          console.error('[nuxt-graphql-client] [GraphQL error]', {
            client: err.client,
            statusCode: err.statusCode,
            operationType: err.operationType,
            operationName: err.operationName,
            gqlError
          })
        }
      }
    })
  })
  
  