import { default as _91_46_46_46path_93NRPDI8yKrXMeta } from "/home/appveyor/projects/swm-niqo/nuxt-app/pages/[...path].vue?macro=true";
import { default as ClientErrorTest407mZHGL4fMeta } from "/home/appveyor/projects/swm-niqo/nuxt-app/pages/ClientErrorTest.vue?macro=true";
import { default as ServerErrorTestzRgPoD5521Meta } from "/home/appveyor/projects/swm-niqo/nuxt-app/pages/ServerErrorTest.vue?macro=true";
export default [
  {
    name: _91_46_46_46path_93NRPDI8yKrXMeta?.name ?? "path",
    path: _91_46_46_46path_93NRPDI8yKrXMeta?.path ?? "/:path(.*)*",
    meta: _91_46_46_46path_93NRPDI8yKrXMeta || {},
    alias: _91_46_46_46path_93NRPDI8yKrXMeta?.alias || [],
    redirect: _91_46_46_46path_93NRPDI8yKrXMeta?.redirect,
    component: () => import("/home/appveyor/projects/swm-niqo/nuxt-app/pages/[...path].vue").then(m => m.default || m)
  },
  {
    name: ClientErrorTest407mZHGL4fMeta?.name ?? "ClientErrorTest",
    path: ClientErrorTest407mZHGL4fMeta?.path ?? "/ClientErrorTest",
    meta: ClientErrorTest407mZHGL4fMeta || {},
    alias: ClientErrorTest407mZHGL4fMeta?.alias || [],
    redirect: ClientErrorTest407mZHGL4fMeta?.redirect,
    component: () => import("/home/appveyor/projects/swm-niqo/nuxt-app/pages/ClientErrorTest.vue").then(m => m.default || m)
  },
  {
    name: ServerErrorTestzRgPoD5521Meta?.name ?? "ServerErrorTest",
    path: ServerErrorTestzRgPoD5521Meta?.path ?? "/ServerErrorTest",
    meta: ServerErrorTestzRgPoD5521Meta || {},
    alias: ServerErrorTestzRgPoD5521Meta?.alias || [],
    redirect: ServerErrorTestzRgPoD5521Meta?.redirect,
    component: () => import("/home/appveyor/projects/swm-niqo/nuxt-app/pages/ServerErrorTest.vue").then(m => m.default || m)
  }
]